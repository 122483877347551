import { React, useRef, useState, useEffect, useNavigate,
    Configure, InstantSearch, Index, history, PropTypes, qs } from '../includes'

import algoliasearch from 'algoliasearch/lite'

import { filtersWithKeyword, getMainFiltersExtra, getFacetFiltersExtra } from './helper'

// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
function getCategorySlug(name) {
    return name
        .split(' ')
        .map(encodeURIComponent)
        .join('+')
}

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
function getCategoryName(slug) {
    return slug
        .split('+')
        .map(decodeURIComponent)
        .join(' ')
}

const DEBOUNCE_TIME = 400

export const nativeSearchClient = algoliasearch(
    algoliaConfig.appId,
    algoliaConfig.key
)

export const ApiConfigWrapper = ({ canSearch, children, engine }) => {
    const hasQueryParam = (requests) => {
        let has = false
        requests.every(({params}) => {
            if (typeof params['query'] !== 'undefined' && params['query'] !== '') {
                has = true
            }
        })

        if ((!engine.events || !engine.events.search) && !searchState) {
            has = false
        }

        return has
    }

    const getSearchClient = (canSearch) => {
        return {
            ...nativeSearchClient,
            search(requests) {
                if (
                    (
                        !engine.fill_empty &&
                        !hasQueryParam(requests)
                    ) ||
                    canSearch() === false
                ) {
                    return Promise.resolve({
                        results: requests.map(() => ({
                            hits: [],
                            nbHits: 0,
                            nbPages: 0,
                            page: 0,
                            processingTimeMS: 0
                        }))
                    })
                }
                return nativeSearchClient.search(requests)
            }
        }
    }

    const navigate = useNavigate()
    const [searchState, setSearchState] = useState(null)
    const debouncedSetStateRef = useRef(null)
    let filters = filtersWithKeyword()
    let facetFilters = getFacetFiltersExtra(engine)
    let mainFilters = getMainFiltersExtra(engine)
    let indexName = algoliaConfig.indexName

    if (engine.filters !== '') {
        filters += (filters !== '' ? ' AND ': '') + engine.filters
    }
    if (mainFilters !== '') {
        filters += (filters !== '' ? ' AND ': '') + mainFilters
    }

    const router = {
        router: history({
            //engine.events && engine.events.update.url
            createURL({ qsModule, routeState, location }) {
                const protocol = window.location.protocol;
                const hostname = window.location.hostname;
                const baseUrl = window.location.pathname;
                const search = window.location.search;

                if (!engine.events || !engine.events.update || !engine.events.update.url) {
                    return `${protocol}//${hostname}${baseUrl}${search}`
                }
                const getUrl = window.location;

                const queryParameters = [];
                let currentRouterState = routeState[Object.keys(routeState)[0]]
                if (currentRouterState && currentRouterState.query) {
                    queryParameters[engine.query_parameter] = encodeURIComponent(currentRouterState.query);
                }
                if (currentRouterState && currentRouterState.page !== 0) {
                    queryParameters.page = currentRouterState.page;
                }
                if (currentRouterState && currentRouterState.menu) {
                    queryParameters[Object.keys(currentRouterState.menu)[0]] =
                        currentRouterState.menu[Object.keys(currentRouterState.menu)[0]]
                }
                if (currentRouterState && currentRouterState.refinementList) {
                    let refinementKeysList = Object.keys(currentRouterState.refinementList)
                    refinementKeysList.map((refinementKey, index) => {
                        queryParameters[refinementKey] = currentRouterState.refinementList[refinementKey]
                    })
                }

                const queryString = qsModule.stringify(Object.assign({}, queryParameters), {
                    addQueryPrefix: true,
                    arrayFormat: 'repeat',
                })

                return `${protocol}//${hostname}${baseUrl}${queryString}`
            },
            parseURL({ qsModule, location }) {
                let aParsed = qsModule.parse(location.search.slice(1));
                const aParsedKey = Object.keys(aParsed)
                if (aParsedKey.length > 0) {
                    aParsed['menu'] = []
                    aParsed['refinementList'] = []
                    aParsedKey.map(key => {
                        let aFacet = engine.facets && engine.facets.refinements ?
                            getFacet(engine.facets.refinements) : []
                        let aSubFacet = engine.facets && engine.facets.refinements && engine.facets.refinements.sub_facets ?
                            getFacet(engine.facets.refinements.sub_facets) : []
                        if (aFacet.includes(key) || aSubFacet.includes(key)) {
                            if (typeof aParsed['refinementList'][key] == 'undefined') {
                                aParsed['refinementList'][key] = []
                            }
                            if (typeof aParsed[key] == 'object') {
                                aParsed['refinementList'][key] = aParsed[key]
                            } else {
                                aParsed['refinementList'][key].push(aParsed[key])
                            }
                        } else if (key !== engine.query_parameter) {
                            aParsed['menu'][key] = aParsed[key]
                        }
                    })
                }

                let aUrl = []
                let aUrlParams = []
                aUrlParams['query'] = decodeURIComponent(aParsed[engine.query_parameter] ?? '')
                aUrlParams['page'] = aParsed['page'] ?? 0
                aUrlParams['menu'] = aParsed['menu'] ?? ''
                aUrlParams['refinementList'] = aParsed['refinementList'] ? Object.assign({}, aParsed['refinementList']) : ''
                aUrl[algoliaConfig.indexName] = aUrlParams

                return Object.assign({}, aUrl);
            }
        }),
    }

    const getFacet = (facets) => {
        if (!facets || !facets.items) {
            return []
        }
        if (typeof facets.multiple != 'undefined' && !facets.multiple) {
            return []
        }

        let list = []
        facets.items.map((facet) => {
            list.push(facet.attribute)
        })

        return list
    }

    const onStateChange = ({ uiState, setUiState }) => {
        setSearchState(true)
        setUiState(uiState)
    };

    return <>
        <InstantSearch
            searchClient={ getSearchClient(canSearch) }
            indexName={ indexName }
            future={{
                preserveSharedStateOnUnmount: false,
            }}
            onStateChange={onStateChange}
            routing={router}
        >
            <Configure
                filters={ filters }
                facetFilters={ facetFilters }
                hitsPerPage={engine.hit_per_page}
            />
            { children }
        </InstantSearch>
    </>
}

ApiConfigWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element
    ])
}
