import { React, PropTypes } from '../../../../includes'

export const AlmaviaItemPagination = ({ item, isActive = false, handlerClick, engine, arrow, disabled }) => {
    const arrowClassIcon = arrow && engine.pagination_block[arrow].icon || ''
    const arrowText = arrow && engine.pagination_block[arrow].text || ''
    return <>
        <li className={ `page-item${isActive ? ` active` : ``}` }>
            {
                !isActive ? <>
                    <a
                        className={`page-link ${disabled ? 'disabled' : ''}`}
                        href={ `#` }
                        data-href={ arrow && arrow === 'next' ? item + 1 : item - 1 }
                        aria-label={ `${Translator.trans(`search.${engine.name}.pagination.go_to_page`, {}, 'algolia')} ${arrow && arrow === 'next' ? item + 1 : item}` }
                        onClick={ handlerClick }
                    >
                        { arrow ? <span className={arrowClassIcon} aria-hidden={true}></span> : item }
                    </a>
                </> : <span className='page-link'>{ item }</span>
            }
        </li>
    </>
}

AlmaviaItemPagination.propTypes = {
    item: PropTypes.number.isRequired,
    isActive: PropTypes.bool,
    handlerClick: PropTypes.func,
    engine: PropTypes.object,
    arrow: PropTypes.string
}
