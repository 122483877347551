import {React, useSearchBox} from '../../includes'

const VirtualBox = ({ query, refine, clear }) => {
    return <></>
}

export const AlmaviaVirtualSearchBox = (props) => {
    const virtualBoxApi = useSearchBox(props);

    return <VirtualBox {...virtualBoxApi} {...props} />;
}
