import {React, useStats, PropTypes, jquery, useHits} from '../../../includes'
import { AlmaviaResultCountAlert } from './AlmaviaResultCountAlert'
import { getSearchKeyword, getTranslatedValue, getChoiceTranslatedValue } from '../../helper'

let alreadyTracked  = false

const Stats = ({ nbHits, query, engine }) => {
    window.gaSearchResultNumber = nbHits
    //!alreadyTracked && window.userActionTagEvents("virtual",{ search_result_number: nbHits, page_name: "Recherche"});
    alreadyTracked = true;

    const searchKeyword = getSearchKeyword(engine)

    const hasCustomTitleInitial = () => {
        let domEngine = jquery('[data-engine="' + engine.name + '"]')
        let customTitle = domEngine.attr('data-title')

        return customTitle?.trim().length > 0
    }

    const getCustomTitleInitial = () => {
        if (!hasCustomTitleInitial()) {
            return null
        }
        let domEngine = jquery('[data-engine="' + engine.name + '"]')

        return domEngine.attr('data-title')
    }
    let searchTitle = getCustomTitleInitial();
    if (searchTitle === null) {
        searchTitle = getTranslatedValue('algolia.search_box.title')
    }

    const facetsTitle = getChoiceTranslatedValue('algolia.stats.found', nbHits, {'count': nbHits})

    const hasQuery = () => {
        return getSearchKeyword(engine).trim() !== ''
    }

    return <>
        {
            <div className={engine.stats.found.container.class}>
                {
                    <h1 className={engine.stats.found.title.class}>{searchTitle}</h1>
                }
                {
                    engine.stats && engine.stats.found && engine.stats.found.results &&
                    engine.stats.found.results.position == 'in_stats' && <p className={engine.stats.found.results.class}>
                        {facetsTitle}
                    </p>
                }
            </div>
        }
        {
            (engine.fill_empty || (!engine.fill_empty && hasQuery())) && nbHits.length === 0 && <>
                <div className={engine.stats.not_found.container.class}>
                    <h1 className={engine.stats.not_found.title.class}>
                        {facetsTitle}
                    </h1>
                    {
                        (engine.show_found_stats && nbHits > engine.max_result_warning && <AlmaviaResultCountAlert />)
                    }
                </div>
                {
                    engine.stats.not_found.advices && <ul className="search-advice">
                        {
                            engine.stats.not_found.advices.keys.map(key =>
                                <li key={key}>{ `${Translator.trans(`search.${engine.name}.advices.${key}`, {}, 'algolia')}` }</li>
                            )
                        }
                    </ul>
                }
            </>
        }
    </>
}

Stats.propTypes = {
    nbHits: PropTypes.number,
    query: PropTypes.string,
    engine: PropTypes.object
}

export const AlmaviaStats = (props) => {
    const statsApi = useStats(props);

    return <Stats {...statsApi} {...props} />;
}
