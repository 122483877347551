import { React, Index, Configure } from '../../../includes'

import { AlmaviaHits } from './Hits/AlmaviaHits'

export const AlmaviaMore = ({ engine }) => {
    return <div className={engine.more.container.class}>
        <h2 className={engine.more.title.class}>
            <span className={engine.more.title.span.class} aria-hidden="true"></span>
            <span>{ `${Translator.trans(`search.${engine.name}.more.search_advice`, {}, 'algolia')}` }</span>
        </h2>
        <div className={engine.more.sub_container.class}>
            {
                (engine.more.indexes).map(index => {
                    <Index
                        indexName={ algoliaConfig.indexName }
                        indexId={ `${algoliaConfig.indexName}_${index.slug}` }
                    >
                        <Configure
                            hitsPerPage={ index.hit_per_page }
                            filters={ index.filters }
                        />
                        <AlmaviaHits engine={engine} />
                    </Index>
                })
            }
        </div>
    </div>
}
