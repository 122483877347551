import {React} from '../../../includes'
import {getTranslatedValue} from "../../helper";

export const AlmaviaResultCountAlert = () => {
    return  <>
        <p className="search-result-alert">
            <span className="atlicon atlicon-circle-exclamation-solid" aria-hidden="true"></span>
            <span>{getTranslatedValue('algolia.stats.warning')}</span>
        </p>
    </>
}