import {React, useState, PropTypes, jquery} from '../../../includes'

import { AlmaviaRefinementList } from './Refinement/AlmaviaRefinementList'
import { AlmaviaSubRefinementList } from './Refinement/AlmaviaSubRefinementList'
import { AlmaviaMenu } from './Refinement/AlmaviaMenu'
import { AlmaviaClearRefinements } from './Refinement/AlmaviaClearRefinements'
import { AlmaviaFacetMobileButton } from './AlmaviaFacetMobileButton'
import {getTranslatedValue} from "../../helper";

export const AlmaviaFacets = ({ engine }) => {
    const updateState = (state) => {
        let button = jquery('[data-engine="' + engine.name + '"]').find('.facets-reinitialize')
        let secondaryFilter = jquery('[data-engine="' + engine.name + '"]').find('.filters-secondary')
        if (state) {
            button.addClass('show')
            secondaryFilter.removeClass('d-none')
        } else {
            button.removeClass('show')
            secondaryFilter.addClass('d-none')
        }
    }

    return <>
        <div className={`${engine.facets.container.class} js-filters`}>
            {
                engine.facets.mobile && <AlmaviaFacetMobileButton mobileButton={engine.facets.mobile}/>
            }
            <div className={engine.facets.sub_container.class}>
                <form className={engine.facets.form.class} id={'formFilter'}>
                    <div className={`filters-primary`}>
                        {
                            engine.facets.refinements.multiple && engine.facets.refinements.items.map(refinement => {
                                return <AlmaviaRefinementList
                                    key={refinement.attribute}
                                    attribute={refinement.attribute}
                                    operator={refinement.operator}
                                    engine={engine}
                                    updateState={updateState}
                                />
                            })
                        }
                        {
                            !engine.facets.refinements.multiple && engine.facets.refinements.items.map(refinement => {
                                return <AlmaviaMenu
                                    key={refinement.attribute}
                                    attribute={refinement.attribute}
                                    operator={refinement.operator}
                                    engine={engine}
                                    updateState={updateState}
                                    limit={50}
                                />
                            })
                        }
                        {
                            engine.facets.refinements.reinitialize &&
                                <AlmaviaClearRefinements engine={engine} />
                        }
                    </div>
                    {
                        engine.facets.refinements.sub_facets && <div className={`filters-secondary d-none`}>
                            {
                                engine.facets.refinements.sub_facets.items.map(refinement => {
                                    /*if (!refinement.depend || refinement.depend === firstFacet) {

                                    }*/
                                    return <AlmaviaSubRefinementList
                                        key={refinement.attribute}
                                        attribute={refinement.attribute}
                                        operator={refinement.operator}
                                        engine={engine}
                                        updateState={updateState}
                                    />
                                })
                            }
                        </div>
                    }
                </form>
                <button className="btn btn-primary filters-close-btn js-btn-dropdown-close js-filter-close d-md-none"
                        title={getTranslatedValue(`algolia.refinement.button`)} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span>{getTranslatedValue(`algolia.refinement.button`)}</span>
                </button>
            </div>
            <div className="filters-backdrop js-filter-close"></div>
        </div>
    </>
}

AlmaviaFacets.propTypes = {
    searchState: PropTypes.object,
    engine: PropTypes.object
}
