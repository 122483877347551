import { React, qs } from '../includes'

const filtersWithKeyword = () => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    let searchFilter = ''

    if (params.has('category')) {
        searchFilter = 'category:' + params.get('category')
    } else if (params.has('familly')) {
        searchFilter = 'familly:' + params.get('familly')
    }

    return searchFilter
}

const getFacetFiltersExtra = (engine) => {
    if (typeof algoliaExtraConfig === 'undefined') {
        return ''
    }

    if (typeof algoliaExtraConfig[engine.name] === 'undefined') {
        return ''
    }

    return algoliaExtraConfig[engine.name].facet_filters ?? ''
}

const getMainFiltersExtra = (engine) => {
    if (typeof algoliaExtraConfig === 'undefined') {
        return ''
    }

    if (typeof algoliaExtraConfig[engine.name] === 'undefined') {
        return ''
    }

    return algoliaExtraConfig[engine.name].main_filters ?? ''
}

const getFieldExtra = (engine, field) => {
    if (typeof algoliaExtraConfig === 'undefined') {
        return ''
    }

    if (typeof algoliaExtraConfig[engine.name] === 'undefined') {
        return ''
    }

    return algoliaExtraConfig[engine.name][field] ?? ''
}

const getSearchKeyword = (engine) => {
    let search = window.location.search
    let params = new URLSearchParams(search)

    if (params.has(engine.query_parameter)) {
        return params.get(engine.query_parameter)
    }

    return ''
}

const getHitUrl = (hit) => {
    // eslint-disable-next-line no-undef
    //const prefix = pathPrefix || ''
    return hit.url//.replace(prefix, '')
}

const getHitImageUrl = (hit) => {
    const imageFields = ['visual', 'picture']

    for (const imageField of imageFields) {
        const fieldIdentifier = hit.content_type_identifier + '_' + imageField + '_thumbnail_uri'
        if (hit[fieldIdentifier] !== undefined) {
            return `/variation/get/${hit['content_id']}/${hit['content_version_no']}/${imageField}/billboard`
        }
    }

    // eslint-disable-next-line no-undef
    return ''//defaultImageUrl
}

const getTranslatedValue = (translationKey, defaultLabel = null, domain = null) => {
    return Translator.trans(translationKey, {}, domain?? 'algolia')
}
const getChoiceTranslatedValue = (translationKey, count  = 0, paramaters = {}, domain = null) => {
    return Translator.transChoice(translationKey, count, paramaters, domain?? 'algolia');
}

const getOutsideFunctions = (functions = {}) => {
    if (Object.keys(functions).length) {
        for (const [key, value] of Object.entries(functions)) {
            value.init()
        }
    }
}

const setDataLayer = (dataLayer = {}, addedName = false, name = '', nameKey = 'event_name') => {
    if (!dataLayer) return ''
    const data = {...dataLayer}
    if (addedName && name && nameKey) {
        const nameValue = dataLayer[nameKey] || ''
        data[nameKey] = `${nameValue} | ${name}`
    }
    return JSON.stringify(data)
}

export { filtersWithKeyword, getFieldExtra, getMainFiltersExtra, getFacetFiltersExtra, getSearchKeyword, getHitUrl, getHitImageUrl, getTranslatedValue, getChoiceTranslatedValue, getOutsideFunctions, setDataLayer }
