import { React, PropTypes, ReactHtmlParser, useState, useEffect } from '../../../../../includes'

export const AlmaviaFetch = ({item, hit}) => {
    const [html, setHTML] = useState();

    useEffect(() => {
        async function createMarkup() {
            let response = await fetch(
                Routing.generate(item.route),
                {
                    method: 'POST',
                    body: JSON.stringify({'contentID': hit['content_id']})
                }
            )

            return await response.text();
        }

        createMarkup().then(response => {
            setHTML(response)
        })
    }, []);

    return <>
        {ReactHtmlParser(html)}
    </>
}

AlmaviaFetch.prototype = {
    contentId: PropTypes.number,
    hit: PropTypes.object
}
