import { React } from '../../../includes';

import { AlmaviaHits } from './Hits/AlmaviaHits'

export const AlmaviaResults = ({ engine }) => {
    return <>
        <div className={engine.results.container.class}>
            {
                engine.results.sub_container ? <div className={engine.results.sub_container.class}>
                    <AlmaviaHits engine={engine} />
                </div> : <AlmaviaHits engine={engine} />
            }
        </div>
    </>
}
