import {React, PropTypes, ReactHtmlParser} from '../../../includes'

export const AlmaviaFacetMobileButton = ({ mobileButton }) => {
    return <button className={`${mobileButton.container.class}`} {...mobileButton.attributes}>
            {
                mobileButton.inner && <>
                    {ReactHtmlParser(mobileButton.inner)}
                </>
            }
        </button>
}

AlmaviaFacetMobileButton.propTypes = {
    mobileButton: PropTypes.object
}
