import { React, useRefinementList } from '../../includes'

const VirtualRefinementList = ({ items, refine, engine, setFirstFacet }) => {
    return <></>
}

export const AlmaviaVirtualRefinementList = (props) => {
    const virtualRefinementApi = useRefinementList(props);

    return <VirtualRefinementList {...virtualRefinementApi} {...props} />;
}
