import { React, PropTypes } from '../../../../includes'

export const AlmaviaRow = ({ hit, engine }) => {
    return <>
        <li>
            <a href={hit[engine.blocks.hits.list.produit.body.fields.url]} className={engine.blocks.hits.list.produit.body.link.class}>
                {hit[engine.blocks.hits.list.produit.body.fields.text]}
            </a>
        </li>
    </>
}

AlmaviaRow.propTypes = {
    hit: PropTypes.object,
    engine: PropTypes.object
}
