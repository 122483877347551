import {React, useMenu} from '../../includes'

const VirtualMenu = ({ items, refine, engine, setFirstFacet }) => {
    return <></>
}

export const AlmaviaVirtualMenu = (props) => {
    const virtualMenuApi = useMenu(props);

    return <VirtualMenu {...virtualMenuApi} {...props} />;
}
