import { React, ReactHtmlParser, PropTypes } from '../../../../includes'

import { AlmaviaFetch } from './Actions/AlmaviaFetch'
import { getFieldExtra } from '../../../helper'

export const AlmaviaHit = ({ hit, hittype, engine, enginehit }) => {
    const isConditionValid = () => {
        if (
            !enginehit.header.pastille.condition ||
            hit[enginehit.header.pastille.condition.field] !== enginehit.header.pastille.condition.value
        ) {
            return true
        }

        return enginehit.header.pastille.condition &&
            hit[enginehit.header.pastille.condition.field] &&
            hit[enginehit.header.pastille.condition.field] === enginehit.header.pastille.condition.value &&
            getFieldExtra(
                engine, enginehit.header.pastille.condition.extra.field
            ) === enginehit.header.pastille.condition.extra.value
    }

    return <>
        <div className={enginehit.container.class}>
            <div className={enginehit.body.link.class}>
                <a href={hit[enginehit.body.fields.url]} className={'card-link'} tabIndex={0} role={'button'}><span>{ enginehit.body.title.class }</span></a>
                <div className={enginehit.header.class}>
                    <div className={`card-category-wrapper`}>
                        <div className={enginehit.header.etiquette.class}>
                            <span
                                className={ `${enginehit.header.logo.class} ${enginehit.header.logo.field_class ? hit[enginehit.header.logo.field_class] : ''}` } aria-hidden="true"></span>
                            <span>{ `${Translator.trans(`search.${engine.name}.hits.header.types.${enginehit.header.logo.field_label ? hit[enginehit.header.logo.field_label] : hittype}`, {}, 'algolia')}` }</span>
                        </div>
                        {
                            enginehit.header.extend && hit[enginehit.header.extend.field] && <div className={enginehit.header.extend.class}>
                                { `${Translator.trans(`search.${engine.name}.hits.header.extend`, {}, 'algolia')}` }
                            </div>
                        }
                    </div>
                    <div className={enginehit.header.image.class}>
                        {
                            hit[enginehit.header.image.field] && hit[enginehit.header.image.field] != 'NA' ? <picture>
                                <source srcSet={`${hit[enginehit.header.image.field]}, ${hit[enginehit.header.image.field + '_retina']}`} media="(min-width: 0)"/>
                                <img className="img-fluid" src={hit[enginehit.header.image.field]} loading="lazy" alt=""/>
                            </picture> : <picture>
                                <img loading="lazy" src={ enginehit.header.image.default } width="375" height="230" alt='' />
                            </picture>
                        }
                    </div>
                    {
                        enginehit.header.icon?.class && <span className={'card-icon'}><span className={ enginehit.header.icon.class } aria-hidden={true}></span></span>
                    }
                    {
                        enginehit.header.pastille && <ul className={enginehit.header.pastille.class}>
                            {
                                isConditionValid() ? enginehit.header.pastille.items.map((item,index) => {
                                    return <AlmaviaFetch key={index} item={item} hit={hit} />
                                }) : <li className={enginehit.header.pastille.item.class}>
                                    {ReactHtmlParser(enginehit.header.pastille.condition.fail.content)}
                                </li>
                            }
                        </ul>
                    }
                </div>
                <div className={ enginehit.body.class }>
                    <h4 className={ enginehit.body.title.class }>
                        <span>{hit[enginehit.body.fields.text]}</span>
                        {
                            enginehit.body.fields.description && hit[enginehit.body.fields.description] && <span>
                                {hit[enginehit.body.fields.description]}
                            </span>
                        }
                    </h4>
                </div>
                {
                    !isConditionValid() && <>
                        {ReactHtmlParser(enginehit.header.pastille.condition.fail.extra_content)}
                    </>
                }
            </div>
        </div>
    </>
}

AlmaviaHit.propTypes = {
    hit: PropTypes.object,
    hittype: PropTypes.string,
    engine: PropTypes.object,
    enginehit: PropTypes.object
}
