import { React, PropTypes, ReactHtmlParser, useState, useEffect } from '../../../../../includes'

export const AlmaviaFetchHit = ({item, route, viewType, pageId}) => {
    const [html, setHTML] = useState();

    useEffect(() => {
        async function createMarkup() {
            let response = await fetch(
                Routing.generate(route, {
                    'contentId': item.content_id,
                    'languageCode': 'fre-FR',
                    'viewType': viewType,
                    'params': {
                        'entity_id': item.entity_id?? '',
                        'pageId': pageId
                    }
                }),
                {
                    method: 'GET',
                }
            )

            return await response.text();
        }

        createMarkup().then(response => {
            setHTML(response)
        })
    }, []);

    return <>
        {ReactHtmlParser(html)}
    </>
}

AlmaviaFetchHit.prototype = {
    viewType: PropTypes.string,
    route: PropTypes.string,
    item: PropTypes.object,
    pageId: PropTypes.number
}
