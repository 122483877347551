import {React, jquery, useClearRefinements} from '../../../../includes'
import {getTranslatedValue} from "../../../helper";

const ClearRefinements = ({ canRefine, refine, engine }) => {
    const reinit = () => {
        jquery('[data-engine="' + engine.name + '"]').find('.facets-reinitialize').removeClass('show')
        jquery('[data-engine="' + engine.name + '"]').find('.filters-secondary').addClass('d-none')
        refine()
    }
    return <div className={`facets-reinitialize filters-refresh`}>
        <a className={"btn-reset js-btn-reset-engine"}
           onClick={reinit}
        >
            <span>{getTranslatedValue(`algolia.refinement.reinitialize`)}</span>
        </a>
    </div>
}

export const AlmaviaClearRefinements = (props) => {
    const clearRefinementApi = useClearRefinements(props);
    return <ClearRefinements {...clearRefinementApi} {...props} />;
}
