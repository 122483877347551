import {React, PropTypes, usePagination, useStats} from '../../../../includes'

import { AlmaviaItemPagination } from './AlmaviaItemPagination'

const Pagination = ({ currentRefinement, nbPages, pages, refine, createURL, engine }) => {
    const handlerRefine = (el, key) => {
        el.preventDefault()
        refine(key || el.target.dataset.href)
        const resultsContainer = document.querySelector(`.${engine.results.container.class}`)
        if (resultsContainer) {
            setTimeout(() => window.scrollTo({
                top: resultsContainer.offsetTop - 150,
                left: 0,
                behavior: 'smooth' }), 500)
        }
    }
    const lowEnd = currentRefinement > 3 ? currentRefinement - 3 : 2
    const highEnd = currentRefinement < nbPages - 4 ? currentRefinement + 3 : nbPages - 1
    const currentArray = (lowEnd = 0, highEnd = nbPages) => {
        let list = []
        for (let i = lowEnd; i <= highEnd; i++) {
            list.push(i)
        }
        return list
    }
    return nbPages > 1 && <nav aria-label="Recherches navigation">
        <ul className="pagination">
            {
                <AlmaviaItemPagination
                    item={ currentRefinement }
                    handlerClick={ handlerRefine }
                    engine={engine}
                    arrow={'prev'}
                    disabled={currentRefinement === 0}
                />
            }
            <AlmaviaItemPagination
                item={ 1 }
                isActive={ currentRefinement === 0 }
                handlerClick={ handlerRefine }
                engine={engine}
            />
            {
                currentRefinement > 5 && <li><span>...</span></li>
            }
            {
                currentArray(lowEnd, highEnd).map(el => <AlmaviaItemPagination
                    key={ el }
                    item={ el }
                    isActive={ currentRefinement === el - 1 }
                    handlerClick={ handlerRefine }
                    engine={engine}
                />)
            }
            {
                currentRefinement < nbPages - 3 && <li><span>...</span></li>
            }
            {
                nbPages > 1 && <AlmaviaItemPagination
                    item={ nbPages }
                    isActive={ currentRefinement === nbPages - 1 }
                    handlerClick={ handlerRefine }
                    engine={engine}
                />
            }
            {
                <AlmaviaItemPagination
                    item={ currentRefinement }
                    handlerClick={ handlerRefine }
                    engine={engine}
                    arrow={'next'}
                    disabled={currentRefinement + 1 === nbPages}
                />
            }
        </ul>
    </nav>
}

Pagination.propTypes = {
    currentRefinement: PropTypes.number,
    nbPages: PropTypes.number,
    refine: PropTypes.func,
    createURL: PropTypes.func,
    engine: PropTypes.object
}

export const AlmaviaPagination = (props) => {
    const paginationApi = usePagination(props);

    return <Pagination {...paginationApi} {...props} />;
}
