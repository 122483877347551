import {React, jquery, useRefinementList, useHits} from '../../../../includes'
import {getTranslatedValue, setDataLayer} from "../../../helper";

const RefinementList = ({ items, refine, engine, updateState, attribute }) => {
    items.sort(compare)
    let itemsSorted = getItemsFacets(items, engine)
    let hasRefined = hasItemRefined(items)
    const dataLayer = engine.facets.refinements.tracking.datalayer || {}
    const dataLayerAddedName = engine.facets.refinements.tracking.add_event_name || false
    if (hasRefined) {
        updateState(true)
    }

    const onChangeRefine = (e, value) => {
        updateState(true)
        refine(value)
    }

    const openCloseDropdown = (e) => {
        let button = e.target
        if (jquery(button).parents('#dropdownSubFilteranotherFilter').length > 0) {
            button = jquery(button).parents('#dropdownSubFilteranotherFilter')[0]
        }
        if (jquery(button).hasClass('opened')) {
            jquery(button).removeClass('opened')
            jquery(button).parent().find('#collapseSubFilteranotherFilter').removeClass('show')
            jquery(button).parent().find('#dropdownSubMenuFilteranotherFilter').removeClass('show')
        } else {
            jquery(button).addClass('opened')
            jquery(button).parent().find('#collapseSubFilteranotherFilter').addClass('show')
            jquery(button).parent().find('#dropdownSubMenuFilteranotherFilter').addClass('show')
        }
    }

    const outsideDropdown = (e) => {
        if (
            e.target.id === 'dropdownSubFilteranotherFilter' ||
            jquery(e.target).parents('#dropdownSubMenuFilteranotherFilter').length === 1 ||
            jquery(e.target).parents('#dropdownSubFilteranotherFilter').length === 1
        ) {
            return;
        }

        jquery('#dropdownSubFilteranotherFilter').removeClass('opened')
        jquery('#collapseSubFilteranotherFilter').removeClass('show')
        jquery('#dropdownSubMenuFilteranotherFilter').removeClass('show')
    }

    const seeResults = (e) => {
        jquery('#dropdownSubFilteranotherFilter').removeClass('opened').removeClass('show')
        jquery('#collapseSubFilteranotherFilter').removeClass('show')
        jquery('#dropdownSubMenuFilteranotherFilter').removeClass('show')
    }

    jquery(document).on("mousedown", outsideDropdown)

    jquery('[data-engine="' + engine.name + '"] .js-filters').removeClass('has-secondary-value')
    if (itemsSorted.length > 0) {
        jquery('[data-engine="' + engine.name + '"] .js-filters').addClass('has-secondary-value')
    }
    return <>

        {
            itemsSorted.length > 0 && <div className={`dropdown-wrapper`}>
                {
                    <p className="label">{Translator.trans(`algolia.refinement.facets.${attribute}.prefix`, {}, 'algolia')}</p>
                }
                <div className="filter-item dropdown">
                    <button className={`dropdown-toggle d-none d-md-flex`}
                            data-bs-display="static" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false" data-bs-auto-close="outside"
                            title={getTranslatedValue(`algolia.refinement.more`)}
                            id={`dropdownSubFilteranotherFilter`}
                            onClick={openCloseDropdown}>
                        <span>{getTranslatedValue(`algolia.refinement.more`)}</span>
                        <span className="atlicon atlicon-angle-down"></span>
                    </button>
                    <div className="collapse show" id="collapseSubFilteranotherFilter">
                        <div className="dropdown-menu" id="dropdownSubMenuFilteranotherFilter">
                            {
                                itemsSorted.map((item) => {
                                    return <div key={item.label} className="filter-item form-check form-switch">
                                        <input
                                            id={`${item.label}SubFilter`} type={`checkbox`} role="switch"
                                            className={`form-check-input ${engine.facets.refinements.tracking.class ? engine.facets.refinements.tracking.class : ''}`}
                                            checked={item.isRefined}
                                            onChange={e => {
                                                onChangeRefine(e, item.value)
                                            }}
                                            data-datalayer-events={setDataLayer(dataLayer, dataLayerAddedName, item.label)}
                                        />
                                        <label className="form-check-label" htmlFor={`${item.label}SubFilter`}>
                                            {getTranslatedValue(`algolia.refinement.facets.${attribute}.${item.label}`)}
                                        </label>
                                    </div>
                                })
                            }
                            {
                                engine.facets.refinements.sub_facets.button && <div className={engine.facets.refinements.sub_facets.button.container.class}>
                                    <button
                                        className={engine.facets.refinements.sub_facets.button.class}
                                        title={getTranslatedValue(`algolia.refinement.button`)} type="button"
                                        data-target-close="#dropdownSubFilteranotherFilter" data-filter-id="anotherSubFilter"
                                        onClick={seeResults}>
                                        <span>{getTranslatedValue(`algolia.refinement.button`)}</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}

function compare( a, b ) {
    if ( a.label < b.label ){
        return -1;
    }
    if ( a.label > b.label ){
        return 1;
    }
    return 0;
}

const getItemsFacets = (items, engine) => {
    if (!engine.facets.refinements.sub_facets.custom_order || engine.facets.refinements.sub_facets.custom_order.length == 0) {
        return items;
    }

    let itemsSorted = []
    let customOrder = engine.facets.refinements.sub_facets.custom_order
    for (let j = 0; items.length > j; j++) {

    }
    for (let i = 0; customOrder.length > i; i++) {
        if (itemInFacetsCustomOrder(items, customOrder[i])) {
            itemsSorted.push(getItemInFacetsCustomOrder(items, customOrder[i]))
        }
    }
    
    for (let i = 0; items.length > i; i++) {
        if (!isInList(items[i], itemsSorted)) {
            itemsSorted.push(items[i])
        }
    }

    return itemsSorted
}

const isInList = (item, itemsSorted) => {
    let inList = false
    itemsSorted.map(itemSorted => {item.label === itemSorted.label ? inList = true: ''})
    return inList
}

const getItemFacetsLabel = (item, engine) => {
    if (!engine.facets.refinements.sub_facets.custom_order) {
        return item.label
    }

    let label = null
    engine.facets.refinements.sub_facets.custom_order.map(custom => {custom.value === item.label ? label = custom.label : ''})
    return label ? label : item.label
}

const itemInFacetsCustomOrder = (items, customOrderElement) => {
    let inFAcets = false
    items.map(item => {customOrderElement.value === item.label ? inFAcets = true : ''})
    return inFAcets
}

const getItemInFacetsCustomOrder = (items, customOrderElement) => {
    let itemFound = null
    items.map(item => {customOrderElement.value === item.label ? itemFound = item : ''})
    return itemFound
}

const hasItemRefined = (items) => {
    let has = false
    items.map(item => {
        if (item.isRefined) {
            has = true
        }
    })

    return has
}

export const AlmaviaSubRefinementList = (props) => {
    const subRefinementApi = useRefinementList(props);

    return <RefinementList {...subRefinementApi} {...props} />;
}
