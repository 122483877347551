import { React, jquery, useRefinementList } from '../../../../includes'
import {getTranslatedValue, setDataLayer} from "../../../helper";

const RefinementList = ({ items, refine, engine, updateState, attribute }) => {
    items.sort(compare)
    let itemsSorted = getItemsFacets(items, engine)
    let hasRefined = hasItemRefined(items)
    const dataLayer = engine.facets.refinements.tracking.datalayer || {}
    const dataLayerAddedName = engine.facets.refinements.tracking.add_event_name || false
    if (hasRefined) {
        updateState(true)
    }

    const onChangeRefine = (e, value) => {
        updateState(true)
        refine(value)
    }

    const openCloseDropdown = (e) => {
        let button = e.target
        if (jquery(button).parents('#dropdownFilteranotherFilter').length > 0) {
            button = jquery(button).parents('#dropdownFilteranotherFilter')[0]
        }
        if (jquery(button).hasClass('opened')) {
            jquery(button).removeClass('opened')
            jquery(button).parent().find('#collapseFilteranotherFilter').removeClass('show')
            jquery(button).parent().find('#dropdownMenuFilteranotherFilter').removeClass('show')
        } else {
            jquery(button).addClass('opened')
            jquery(button).parent().find('#collapseFilteranotherFilter').addClass('show')
            jquery(button).parent().find('#dropdownMenuFilteranotherFilter').addClass('show')
        }
    }

    const outsideDropdown = (e) => {
        if (
            e.target.id === 'dropdownFilteranotherFilter' ||
            jquery(e.target).parents('#dropdownMenuFilteranotherFilter').length === 1 ||
            jquery(e.target).parents('#dropdownFilteranotherFilter').length === 1
        ) {
            return;
        }

        jquery('#dropdownFilteranotherFilter').removeClass('opened')
        //jquery('#collapseFilteranotherFilter').removeClass('show')
        jquery('#dropdownMenuFilteranotherFilter').removeClass('show')
    }

    const seeResults = (e) => {
        jquery('#dropdownFilteranotherFilter').removeClass('opened').removeClass('show')
        //jquery('#collapseFilteranotherFilter').removeClass('show')
        jquery('#dropdownMenuFilteranotherFilter').removeClass('show')
    }

    jquery(document).on("mousedown", outsideDropdown)

    jquery('[data-engine="' + engine.name + '"] .js-filters').removeClass('has-primary-value')
    if (itemsSorted.length > 0) {
        jquery('[data-engine="' + engine.name + '"] .js-filters').addClass('has-primary-value')
    }

    return <>
        {
            itemsSorted.map((item, index) => {
                return !engine.facets.refinements.max_items || index < engine.facets.refinements.max_items && <div key={item.label} className="filter-item form-check form-switch">
                    <input id={`${item.label}Filter`} role="switch"
                           type={`checkbox`}
                           className={`form-check-input ${engine.facets.refinements.tracking.class ? engine.facets.refinements.tracking.class : ''}`}
                           checked={item.isRefined}
                           onChange={e => {
                               onChangeRefine(e, item.value)
                           }}
                           name={engine.facets.refinements.multiple ? `${item.label}Filter` : `facetsFilter`}
                           data-datalayer-events={setDataLayer(dataLayer, dataLayerAddedName, item.label)}
                    />
                    <label className="form-check-label"
                           htmlFor={`${item.label}Filter`}
                    >
                        {getTranslatedValue(`algolia.refinement.facets.${attribute}.${item.label}`)}
                    </label>
                </div>
            })
        }
        {
            engine.facets.refinements.max_items && itemsSorted.length > engine.facets.refinements.max_items && <div className="filter-item dropdown">
                <button className={`dropdown-toggle d-none d-md-flex`}
                        data-bs-display="static" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" data-bs-auto-close="outside"
                        title={getTranslatedValue(`algolia.refinement.more`)}
                        id={`dropdownFilteranotherFilter`}
                        onClick={openCloseDropdown}>
                    <span>{getTranslatedValue(`algolia.refinement.more`)}</span>
                    <span className="atlicon atlicon-angle-down"></span>
                </button>
                <div className="collapse show" id="collapseFilteranotherFilter">
                    <div className="dropdown-menu" id="dropdownMenuFilteranotherFilter">
                        {
                            itemsSorted.map((item, index) => {
                                return index >= engine.facets.refinements.max_items && <div key={item.label} className="form-check form-switch">
                                    <input
                                        id={`${item.label}Filter`} type={`checkbox`} role="switch"
                                        className={`form-check-input ${engine.facets.refinements.tracking.class ? engine.facets.refinements.tracking.class : ''}`}
                                        checked={item.isRefined}
                                        onChange={e => {
                                            onChangeRefine(e, item.value)
                                        }}
                                        data-datalayer-events={setDataLayer(dataLayer, dataLayerAddedName, item.label)}
                                    />
                                    <label className="form-check-label" htmlFor={`${item.label}Filter`}>
                                        {getTranslatedValue(`algolia.refinement.facets.${attribute}.${item.label}`)}
                                    </label>
                                </div>
                            })
                        }
                        {
                            engine.facets.refinements.button && <div className={engine.facets.refinements.button.container.class}>
                                <button
                                    className={engine.facets.refinements.button.class}
                                    title={getTranslatedValue(`algolia.refinement.button`)} type="button"
                                    data-target-close="#dropdownFilteranotherFilter" data-filter-id="anotherFilter"
                                    onClick={seeResults}>
                                    <span>{getTranslatedValue(`algolia.refinement.button`)}</span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </>
}

function compare( a, b ) {
    if ( a.label < b.label ){
        return -1;
    }
    if ( a.label > b.label ){
        return 1;
    }
    return 0;
}

const getItemsFacets = (items, engine) => {
    if (!engine.facets.refinements.custom_order || engine.facets.refinements.custom_order.length == 0) {
        return items;
    }

    let itemsSorted = []
    let customOrder = engine.facets.refinements.custom_order
    for (let j = 0; items.length > j; j++) {

    }
    for (let i = 0; customOrder.length > i; i++) {
        if (itemInFacetsCustomOrder(items, customOrder[i])) {
            itemsSorted.push(getItemInFacetsCustomOrder(items, customOrder[i]))
        }
    }
    
    for (let i = 0; items.length > i; i++) {
        if (!isInList(items[i], itemsSorted)) {
            itemsSorted.push(items[i])
        }
    }

    return itemsSorted
}

const isInList = (item, itemsSorted) => {
    let inList = false
    itemsSorted.map(itemSorted => {item.label === itemSorted.label ? inList = true: ''})
    return inList
}

const getItemFacetsLabel = (item, engine) => {
    if (!engine.facets.refinements.custom_order) {
        return item.label
    }

    let label = null
    engine.facets.refinements.custom_order.map(custom => {custom.value === item.label ? label = custom.label : ''})
    return label ? label : item.label
}

const itemInFacetsCustomOrder = (items, customOrderElement) => {
    let inFAcets = false
    items.map(item => {customOrderElement.value === item.label ? inFAcets = true : ''})
    return inFAcets
}

const getItemInFacetsCustomOrder = (items, customOrderElement) => {
    let itemFound = null
    items.map(item => {customOrderElement.value === item.label ? itemFound = item : ''})
    return itemFound
}

const hasItemRefined = (items) => {
    let has = false
    items.map(item => {
        if (item.isRefined) {
            has = true
        }
    })

    return has
}

export const AlmaviaRefinementList = (props) => {
    const refinementApi = useRefinementList(props);

    return <RefinementList {...refinementApi} {...props} />;
}
