import {React, useStats, PropTypes, jquery} from '../../../includes'

import {getChoiceTranslatedValue, getSearchKeyword} from '../../helper'
import {AlmaviaResultCountAlert} from "./AlmaviaResultCountAlert";

const Stats = ({ nbHits, query, engine }) => {
    const searchKeyword = getSearchKeyword(engine)

    const hasQuery = () => {
        return getSearchKeyword(engine).trim() !== ''
    }
    return <>
        {
            engine.show_found_stats && hasQuery() && <p className={engine.stats.found.results.class}>
                {getChoiceTranslatedValue('algolia.stats.found', nbHits, {'count': nbHits})}
            </p>
        }
        {
            (engine.show_found_stats && nbHits > engine.max_result_warning && <AlmaviaResultCountAlert />)
        }
    </>
}

Stats.propTypes = {
    nbHits: PropTypes.number,
    query: PropTypes.string,
    engine: PropTypes.object
}

export const AlmaviaStatsMinimal = (props) => {
    const statsMinimalApi = useStats(props);

    return <Stats {...statsMinimalApi} {...props} />;
}
