import {React, createRoot, BrowserRouter, jquery} from './includes'

import Search from './Engine/Search'

let search = (function () {
    function _init() {
        const algoliaSearch = jquery('.section-search-engine')
        if (algoliaSearch.length > 0) {
            algoliaSearch.each(function() {
                let engine = jquery(this).attr('data-engine')
                const root = createRoot(this)
                root.render(
                    <BrowserRouter>
                        <Search
                            engine={algoliaConfig.engine[engine]}
                        />
                    </BrowserRouter>
                )
            })

            if (jquery('.js-header-search-toggle').length > 0) {
                const headerMobileOpenSearch = jquery('.section-search-engine')
                headerMobileOpenSearch.each(function() {
                    let engineHeader = jquery(this).attr('data-engine-header')
                    if (engineHeader) {
                        headerMobileOpenSearch.on('click', function() {
                            e.preventDefault()
                            jquery(this).addClass('active')
                        })
                    }
                })
            }
        }
    }

    return {init: _init}
})()

search.init()
