import { React, PropTypes } from '../../../../includes'

export const AlmaviaSuggestion = ({ hit, engine }) => {
    return <>
        <li>
            <a href={`${engine.form.suggestions.action}?${engine.query_parameter}=${hit['query']}`} className={`search-animate js-search-animate`}>
                {hit['query']}
            </a>
        </li>
    </>
}

AlmaviaSuggestion.propTypes = {
    hit: PropTypes.object,
    engine: PropTypes.object
}
