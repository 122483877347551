import {React, usePagination, PropTypes} from '../../includes'

const VirtualPagination = ({ currentRefinement, nbPages, pages, refine, createURL, engine }) => {
    return <></>
}

VirtualPagination.propTypes = {
    currentRefinement: PropTypes.number,
    nbPages: PropTypes.number,
    refine: PropTypes.func,
    createURL: PropTypes.func,
    engine: PropTypes.object
}

export const AlmaviaVirtualPagination = (props) => {
    const virtualPaginationApi = usePagination(props);

    return <VirtualPagination {...virtualPaginationApi} {...props} />;
}
