import {React, useRef, useState, useEffect, useHits, Slider, PropTypes, useSearchBox} from '../../../../includes'

import { AlmaviaHit } from './AlmaviaHit'
import { AlmaviaProduct } from './AlmaviaProduct'
import { AlmaviaSuggestion } from "./AlmaviaSuggestion";

function BtnNextArrow(props) {
    const { onClick, className } = props
    return (
        <button onClick={onClick} type="button" className={ className } aria-label="Suivant"><span className="atlicon atlicon-angle-right" aria-hidden="true"></span></button>
    );
}

function BtnPrevArrow(props) {
    const { onClick, className } = props
    return (
        <button onClick={onClick} type="button" className={ className } aria-label="Précédent"><span className="atlicon atlicon-angle-left" aria-hidden="true"></span></button>
    )
}

//atlicon-lightbulb-o atlicon-bars-sort atlicon-comment atlicon-logo
const Hits = ({ hits, type, setNbResult, engine }) => {
    const [currentSlide, setCurrentSlide] = useState(0)

    const handleBeforeChange = (_, next) => setCurrentSlide(next)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        // adaptiveHeight: true,
        prevArrow: <BtnPrevArrow />,
        nextArrow: <BtnNextArrow />,
        beforeChange: handleBeforeChange
    }

    const sliderRef = useRef(null)

    useEffect(() => {
        if (sliderRef.current?.slickGoTo) {
            setCurrentSlide(0)
            sliderRef.current.slickGoTo(0)
        }
    },[hits])

    setNbResult(type, hits.length)

    return <>
        {
            hits.length > 0 ? (
                <div className={engine.blocks.hits.container.class}>
                    <p className={engine.blocks.hits.title.class}>
                        <span className={engine.blocks.hits.list[type].icon.class} aria-hidden='true'></span>
                        <span>{ Translator.trans(`search.${engine.name}.blocks.${type}`, {}, 'algolia') }</span>
                    </p>
                    {
                        type !== 'product' ? <ul className={engine.blocks.hits.list_container.class}>
                            {hits.map(hit => type !== 'suggestion' ? <AlmaviaHit
                                    key={ hit.objectID }
                                    hit={ hit }
                                    engine={ engine }
                                    enginehit={ engine.blocks.hits.list[type] } /> :
                                <AlmaviaSuggestion key={hit.objectID} hit={ hit } engine={engine} />)}
                        </ul> : <>
                            <Slider ref={sliderRef} className={engine.blocks.hits.list[type].slider.class} {...settings}>
                                {hits.map(hit => <AlmaviaProduct key={hit.objectID} hit={ hit } engine={engine} />)}
                            </Slider>
                            <div className={engine.blocks.hits.list[type].slider.counter.class}>
                                <strong>{currentSlide + 1}</strong> / {hits.length}
                            </div>
                        </>
                    }
                </div>
            ) : ''
        }
    </>
}

Hits.propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
    setNbResult: PropTypes.func.isRequired,
    settings: PropTypes.object,
    engine: PropTypes.object
}

export const AlmaviaHits = (props) => {
    const hitsApi = useHits(props);

    return <Hits {...hitsApi} {...props} />;
}
