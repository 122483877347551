import { React, PropTypes } from '../../../../includes'

export const AlmaviaHit = ({ hit, enginehit }) => {
    return <>
        <li>
            {
                enginehit && <a href={hit[enginehit.body.fields.url]} className={enginehit.body.link.class}>
                    {hit[enginehit.body.fields.text]}
                </a>
            }
        </li>
    </>
}

AlmaviaHit.propTypes = {
    hit: PropTypes.object,
    enginehit: PropTypes.object
}
