import {React, useEffect, PropTypes, useHits, jquery, ReactHtmlParser} from '../../../../includes'
import { getOutsideFunctions } from '../../../helper'

import { AlmaviaHit } from './AlmaviaHit'
import {AlmaviaFetchHit } from './Actions/AlmaviaFetchHit'
const Hits = ({ hits, engine, results }) => {
    jquery('[data-engine="' + engine.name + '"] .' + engine.results.container.class).removeClass('has-value')
    if (hits.length > 0) {
        jquery('[data-engine="' + engine.name + '"] .' + engine.results.container.class).addClass('has-value')
    }

    const customOutsideFn = {...window?.customSearchFn?.searchPageResults}

    useEffect(() => {
        getOutsideFunctions(customOutsideFn)
    }, [results, hits])
    
    return <>
        {
            results.nbHits >= 100 && engine.results.more_cent_results && <>
                {ReactHtmlParser(engine.results.more_cent_results)}
            </>
        }
        {
            hits.map((hit) => {
                if (engine.blocks.hits.route.path){
                    return <div className={'search-result-item col-12 col-sm-6 col-lg-4'} key={ hit.objectID }>
                        <AlmaviaFetchHit
                            key={ hit.objectID }
                            viewType={engine.blocks.hits.route.viewType}
                            item={hit}
                            route={engine.blocks.hits.route.path}
                            pageId={engine.search_engine_content_id}
                        /></div>
                }
                else if (typeof engine.blocks.hits.list[hit[engine.blocks.hits.mapping.field]] != 'undefined') {
                    return <AlmaviaHit
                        key={ hit.objectID }
                        hit={ hit }
                        engine={ engine }
                        enginehit={ engine.blocks.hits.list[hit[engine.blocks.hits.mapping.field]] }
                        hittype={ hit[engine.blocks.hits.mapping.field] } />
                } else {
                    console.error('The object "' + hit['content_id'] + '" of type : "' + hit[engine.blocks.hits.mapping.field] + '" is not defined in field : "' + engine.blocks.hits.mapping.field + '".')
                }
            })
        }
    </>
}

Hits.propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object),
    engine: PropTypes.object,
    results: PropTypes.object
}

export const AlmaviaHits = (props) => {
    const hitsApi = useHits(props);

    return <Hits {...hitsApi} {...props} />;
}
