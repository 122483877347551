import {React, useState, Component, ApiConfigWrapper, useSearchBox, jquery} from '../includes'

import { AlmaviaSearchBox } from "./Search/AlmaviaSearchBox"
import { AlmaviaVirtualSearchBox } from "./Search/AlmaviaVirtualSearchBox"
import { AlmaviaVirtualPagination } from "./Search/AlmaviaVirtualPagination"
import { AlmaviaVirtualMenu } from "./Search/AlmaviaVirtualMenu"
import { AlmaviaVirtualRefinementList } from "./Search/AlmaviaVirtualRefinementList"
import { AlmaviaBlocks } from "./Search/Header/AlmaviaBlocks"
import { AlmaviaMore } from "./Search/Page/AlmaviaMore"
import { AlmaviaResults } from "./Search/Page/AlmaviaResults"
import { AlmaviaStats } from "./Search/Page/AlmaviaStats"
import { AlmaviaStatsMinimal } from "./Search/Page/AlmaviaStatsMinimal"
import { AlmaviaFacets } from "./Search/Page/AlmaviaFacets"
import { AlmaviaPagination } from "./Search/Page/Pagination/AlmaviaPagination"
import {AlmaviaFetch} from "./Search/Page/Refinement/Actions/AlmaviaFetch";

class Search extends Component {

    constructor(props) {
        super(props)
        this.engine = props.engine
        this.wrapperRef = React.createRef()
        this.closeButton = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleCloseSearchMobile = this.handleCloseSearchMobile.bind(this)
        this.nbHits = 0
    }

    componentDidMount() {
        jquery(document).on("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        jquery(document).on("mousedown", this.handleClickOutside)
    }
    handleClickOutside(event) {
        let searchHeader = jquery('[data-engine="' + this.engine.name + '"]')
        if (searchHeader && searchHeader.length > 0 && !searchHeader[0].contains(event.target)) {
            let searchResult = searchHeader.find('.js-header-search-result')
            
            if (searchResult.length > 0) {
                searchResult.removeClass('show')
                searchHeader.removeClass('has-value')
            }
        }
    }

    handleCloseSearchMobile() {
        if (this.closeButton) {
            let searchHeader = jquery('[data-engine="' + this.engine.name + '"]')
            if (searchHeader.hasClass('js-header-search')) {
                searchHeader.removeClass('active')
            }
        }
    }

    render() {
        const canSearch = () => {
            let searchElem = getBeginSearch(currentSearch)

            if (searchElem && !searchElem.has_result) {
                return false
            }
            return true
        }

        const setNbHits = (nbHits) => {
            window.searchEngine[this.engine].nbHits = nbHits
        }

        const getNbHits = () => {
            return window.searchEngine[this.engine].nbHits
        }

        let previousSearch = []
        let currentSearch = ''

        const setHasResult = (result) => {
            if (currentSearch.trim() !== '') {
                let searchIndex = getSearchIndex(currentSearch)
                if (searchIndex !== null) {
                    previousSearch[searchIndex].has_result = result
                } else if (!result) {
                    previousSearch.push({'search': currentSearch, 'has_result': result})
                }
            }
        }

        const setPreviousSearch = (search) => {
            currentSearch = search
        }

        const getSearchIndex = (search) => {
            let searchIndex = null
            jquery(previousSearch).each(function (index, elem) {
                if (elem.search === search) {
                    searchIndex = index
                }
            })

            return searchIndex
        }

        const getBeginSearch = (searchValue) => {
            let search = null
            jquery(previousSearch).each(function (index, elem) {
                if (searchValue.startsWith(elem.search)) {
                    search = elem
                }
            })

            return search
        }

        return <ApiConfigWrapper canSearch={canSearch} engine={this.engine}>
            <div
                className={this.engine.container.class.main}
                ref={this.wrapperRef}
            >
                {
                    this.engine.header ? <>
                            {
                                this.engine.burger && <>
                                    <button
                                        className={this.engine.burger.button && this.engine.burger.button.class ? this.engine.burger.button.class : ''}
                                        role={'button'}
                                        onClick={() => this.handleCloseSearchMobile()}
                                        ref={this.closeButton}
                                    >
                                        {
                                            this.engine.burger.button.span &&
                                                <span className={this.engine.burger.button.span.class} aria-hidden={true}></span>
                                        }
                                    </button>
                                </>
                            }
                            <AlmaviaSearchBox
                                setPreviousSearch={setPreviousSearch}
                                engine={this.engine}
                                placeholderValue={'algolia.search_box.placeholder_header'}
                            />
                            <AlmaviaBlocks
                                setHasResult={setHasResult}
                                engine={this.engine}
                            />
                            <AlmaviaVirtualPagination />
                            <AlmaviaVirtualMenu
                                attribute={`default_menu`}
                            />
                            <AlmaviaVirtualRefinementList
                                attribute={`default_refinement`}
                            />
                        </>
                        : <>
                        {
                            (this.engine.stats || this.engine.search_box) && <div className={this.engine.container.class.header}>
                                <div className={this.engine.container.class.header_sub_container}>
                                    {
                                        this.engine.stats && <AlmaviaStats engine={this.engine} />
                                    }
                                    {
                                        this.engine.search_box ? <AlmaviaSearchBox setPreviousSearch={setPreviousSearch} type={'page'} engine={this.engine} />
                                            : <AlmaviaVirtualSearchBox />

                                    }
                                </div>
                            </div>
                        }
                        <div className={this.engine.container.class.body}>
                            <div className={this.engine.container.class.body_sub_container}>
                                {
                                    this.engine.stats && this.engine.stats.found && this.engine.stats.found.results &&
                                    this.engine.stats.found.results.position == 'under_stats' &&
                                    <AlmaviaStatsMinimal engine={this.engine} />
                                }
                                {
                                    this.engine.facets && this.engine.facets.refinements &&
                                    this.engine.facets.refinements.items && <AlmaviaFacets engine={this.engine} />
                                }
                                <div className={'js-filter-flag'} />
                                <AlmaviaResults engine={this.engine} />
                                {
                                    this.engine.pagination && <AlmaviaPagination engine={this.engine} />
                                }
                                {
                                    this.engine.more && <AlmaviaMore engine={this.engine} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </ApiConfigWrapper>
    }
}

export default Search
