import {React, Configure, Index, jquery, PropTypes} from '../../../includes'

import { AlmaviaHits } from './Hits/AlmaviaHits'

export const AlmaviaBlocks = ({setHasResult, engine}) => {

    let nbResult = []
    engine.blocks.indexes.map(index => {
        nbResult[index.slug] = null
    })

    const setNbResult = (index, nb) => {
        nbResult[index] = nb
        canDisplayNoResult()
    }

    const canDisplayNoResult = () => {
        let hasResult = null

        Object.keys(nbResult).forEach(function(key) {
            if (nbResult[key] !== null && nbResult[key] > 0) {
                hasResult = true
            }
        })

        let searchHeader = jquery('[data-engine="' + engine.name + '"]')
        if (hasResult === true) {
            setHasResult(true)
            if (searchHeader.length > 0) {
                searchHeader.addClass('has-value')
                searchHeader.find('.js-header-search-result').addClass('show')
            }
        } else {
            setHasResult(false)
            if (searchHeader.length > 0) {
                searchHeader.removeClass('has-value')
                searchHeader.find('.js-header-search-result').removeClass('show')
            }
        }
    }

    return <>
        <div className={engine.blocks.container.class} tabIndex={0}>
            {
                (engine.blocks.indexes).map(index => {
                    return <Index
                        indexName={ `${algoliaConfig.indexName}${index.suffix}` }
                        indexId={ `${algoliaConfig.indexName}_${index.slug}` }
                        key={index.slug}
                    >
                        <Configure
                            hitsPerPage={ index.hit_per_page }
                            filters={ index.filters }
                        />
                        <AlmaviaHits 
                            setNbResult={setNbResult} 
                            engine={engine}
                            type={ index.slug } />
                    </Index>
                })
            }
            <div className={engine.stats.not_found.container.class} style={{ display: 'none' }}>
                { `${Translator.trans(`search.${engine.name}.not_found`, {}, 'algolia')}` }
            </div>
        </div>
    </>
}

AlmaviaBlocks.propTypes = {
    setHasResult: PropTypes.func,
    engine: PropTypes.object
}
