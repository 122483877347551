import { React, useState, useSearchBox, PropTypes, jquery } from '../../includes'
import { getTranslatedValue } from '../helper'

const SearchBox = ({ setPreviousSearch, defaultRefinement, query, refine, engine, type, placeholderValue }) => {
    const [initialState, setInitialState] = useState(true)
    const [isFocus, setIsFocus] = useState(query || defaultRefinement || false)
    const [isValid, setIsValid] = useState(false)
    const placeholder = getTranslatedValue(placeholderValue? placeholderValue : 'algolia.search_box.placeholder')
    const classValid = isValid ? 'no-valid' : 'valid'
    let searchBloc = jquery('[data-engine="' + engine.name + '"]')
    let searchHeaderResult = searchBloc.find('.js-header-search-result')


    const displayResult = () => {
        if (searchHeaderResult.length > 0) {
            searchHeaderResult.addClass('show')
            searchBloc.addClass('has-value')
        }
    }

    const hideResult = () => {
        if (searchHeaderResult.length > 0) {
            searchHeaderResult.removeClass('show')
            searchBloc.removeClass('has-value')
        }
    }

    const handlerChange = (e) => {
        setInitialState(false)
        refine(e.target.value)
        setPreviousSearch(e.target.value)
        hasBlockFilled()
    }

    const handleSubmit = (event) => {
        event.target[0].value = event.target[0].value.trim()
        if (((engine.events.update && engine.events.update.results && !query) || (engine.form.input.required && !event.target[0].value)) ) {
            event.preventDefault()
            setIsValid(true)
        }
    }

    const focused = () => {
        setIsFocus(true)
        setIsValid(false)
        hasBlockFilled()
    }

    const hasBlockFilled = () => {
        let blocFilled = searchBloc.find('.search-category')
        if (blocFilled.length > 0 && jquery('#query-' + engine.name).length > 0 && jquery('#query-' + engine.name).val().trim() !== '') {
            displayResult()
        } else {
            hideResult()
        }
    }

    const clearHandler = () => {
        refine('')
        jquery('#query-' + engine.name).val('')
        focused()
        jquery('#query-' + engine.name).focus()
    }

    const getValue = () => {
        if (initialState && !engine.events.search) {
            return ``
        }

        return query
    }

    if (type && type === 'page') return <form className={`${engine.form_page.class} ${classValid}`} action={engine.form_page.action} method="get"
                                              noValidate={true} autoComplete={'off'} onSubmit={ handleSubmit }>
        <div className={engine.form_page.input.container.class}>
            <div className={engine.form_page.input.sub_container.class}>
                {
                    engine.events.update && engine.events.update.results ?
                        <input id={`query-${engine.name}`} name={engine.query_parameter} className={engine.form_page.input.class} type="search"
                               placeholder={ placeholder }
                               title={ placeholder }
                               required={true}
                               value={ getValue() }
                               onChange={ handlerChange }
                               onFocus={ focused }
                               aria-required={true}
                        /> : <input id={`query-${engine.name}`} name={engine.query_parameter} className={engine.form_page.input.class} type="search"
                                    placeholder={ placeholder }
                                    title={ placeholder }
                                    required={true}
                                    defaultValue={ query }
                                    onFocus={ focused }
                                    aria-required={true}
                        />
                }
                {
                    typeof InstallTrigger !== 'undefined' && engine.form_page.cancel &&
                    <div className={engine.form_page.cancel.class} onClick={ clearHandler }></div>
                }
                <div className="invalid-feedback d-md-none">
                    { Translator.trans(`search.${engine.name}.fill_search`, {}, 'algolia') }
                </div>
            </div>
        </div>
        <div className={engine.form_page.button.container.class}>
            {
                engine.form_page.button.type === 'button' && <button type="submit" className={engine.form_page.button.submit.class}>
                    <span>{ Translator.trans(engine.form_page.button.text, {}, 'algolia') }</span>
                </button>
            }
            {
                engine.form_page.button.type === 'icone' && <button type="submit" className={engine.form_page.button.submit.class}>
                    <span className={engine.form_page.button.submit.span.class} aria-hidden="true"></span>
                    <span className="visually-hidden">{ Translator.trans(`search.${engine.name}.search`, {}, 'algolia') }</span>
                </button>
            }
        </div>
    </form>

    return <form className={`${engine.form.class} ${classValid}`} action={engine.form.action} method="get"
          noValidate={true} autoComplete={'off'} onSubmit={ handleSubmit }>
        <div className={engine.form.input.container.class}>
            <div className={engine.form.input.sub_container.class}>
                <label htmlFor={`query-${engine.name}`} className="visually-hidden">{Translator.trans(`search.${engine.name}.fill_search`, {}, 'algolia')}</label>
                {
                    engine.events.update && engine.events.update.results ?
                        <input id={`query-${engine.name}`} name={engine.query_parameter} className={engine.form.input.class} type="search"
                               placeholder={ placeholder }
                               title={ placeholder }
                               required={true}
                               value={ getValue() }
                               onChange={ handlerChange }
                               onFocus={ focused }
                               aria-required={true}
                        /> : <input id={`query-${engine.name}`} name={engine.query_parameter} className={engine.form.input.class} type="search"
                                    placeholder={ placeholder }
                                    title={ placeholder }
                                    required={true}
                                    defaultValue={ query }
                                    onFocus={ focused }
                                    aria-required={true}
                        />
                }
                {
                    typeof InstallTrigger !== 'undefined' && engine.form.cancel &&
                    <div className={engine.form.cancel.class} onClick={ clearHandler }></div>
                }
                <div className="invalid-feedback d-md-none">
                    { Translator.trans(`search.${engine.name}.fill_search`, {}, 'algolia') }
                </div>
            </div>
        </div>
        <div className={engine.form.button.container.class}>
            {
                engine.form.button.type === 'button' && <button type="submit" className={engine.form.button.submit.class}>
                    <span>{ Translator.trans(`search.${engine.name}.search`, {}, 'algolia') }</span>
                </button>
            }
            {
                engine.form.button.type === 'icone' && <button type="submit" className={engine.form.button.submit.class}>
                    <span className={engine.form.button.submit.span.class} aria-hidden="true"></span>
                    <span className="visually-hidden">{ Translator.trans(`search.${engine.name}.search`, {}, 'algolia') }</span>
                </button>
            }
        </div>
    </form>
}

export function AlmaviaSearchBox(props) {
    const searchBoxApi = useSearchBox(props);

    return <SearchBox {...searchBoxApi} {...props} />;
}
