import { React, PropTypes } from '../../../../includes'

export const AlmaviaProduct = ({ hit, engine }) => {
    return <>
        <div className={engine.blocks.hits.list.product.container.class}>
            <div className={engine.blocks.hits.list.product.sub_container.class}>
                <div className={engine.blocks.hits.list.product.header.class}>
                    <div className={engine.blocks.hits.list.product.header.image.class}>
                        {
                            hit[engine.blocks.hits.list.product.header.image.field] && hit[engine.blocks.hits.list.product.header.image.field] != 'NA' ? <picture>
                                <source srcSet={`${hit[engine.blocks.hits.list.product.header.image.field]}, ${hit[engine.blocks.hits.list.product.header.image.field + '_retina']}`} media="(min-width: 0)"/>
                                <img className="img-fluid" loading="lazy" src={hit[engine.blocks.hits.list.product.header.image.field]} width="293" height="170" alt="" />
                            </picture> : <picture>
                                <img src={engine.blocks.hits.list.product.header.image.default} width="293" height="170" alt='' />
                            </picture>
                        }
                    </div>
                </div>
                <div className={engine.blocks.hits.list.product.body.class}>
                    <h4 className={engine.blocks.hits.list.product.body.title.class}>
                        <a className={engine.blocks.hits.list.product.body.link.class} href={hit[engine.blocks.hits.list.product.body.fields.url]}>{hit[engine.blocks.hits.list.product.body.fields.text]}</a>
                    </h4>
                </div>
            </div>
        </div>
    </>
}

AlmaviaProduct.propTypes = {
    hit: PropTypes.object,
    engine: PropTypes.object
}
